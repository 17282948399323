import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Footer from './view/common/Footer';
import Header from './view/common/Header';
import Entry from './view/Entry';
import Home from './view/Home';
import Syllabus from './view/Syllabus';
import Welcome from "./view/Welcome";

export default function App() {
	return (
		<BrowserRouter basename='/'>
			<Header />
			<main className='document__main' role="main">
				<div className='container h-100'>
					<div className='row h-100'>
						<div className='col-12 h-100'>
							<Routes>
								<Route path='/'>
									<Route index element={<Home />} />
									<Route path=':id'>
										<Route index element={<Syllabus />} />
										<Route path='add' element={<Entry />} />
										<Route path='welcome' element={<Welcome />} />
									</Route>
								</Route>
							</Routes>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</BrowserRouter>
	);
}
