import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../controller/Loading";
import AlertBox from "../controller/AlertBox";
import Fetch from "../controller/Fetch";
import Crumb from "../controller/Crumb";
import { ConvertToDisplayDate, CourseFree, Domain } from "../controller/keys";
import Escape from "../controller/Escape";
import { getAccessToken } from "..";

export default function Home(){
    const {id}=useParams();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(false);
    const [Course,setCourse]=useState();
    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            'url':'v1/syllabus/'+id,
            'method':'GET'
        }
        const token=getAccessToken();
        if(!token['AccessToken']){
            send['url']+='/show';
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setCourse(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    }
    return (
        <div>
            {Course ?<Crumb CurrentText={Course['CourseName']} items={[{index:0,href:"/",text:"コース検索"}]} />:""}
            {Alert ?<AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Course?
            <>
                <div className="row mb-3">
                    <div className="col-12">
                        <Link to={'add'} className="btn btn-primary">受講登録</Link>
                    </div>
                </div>{Course['condition']?
                    <div>
                        <>{Course['condition']['teacher']?
                        <div className="alert alert-info">
                            講師登録しています
                        </div>:""}
                        </>
                        <>{Course['condition']['student']?
                        <div className="row my-2">{Course['condition']['student'].map(student=>
                            <div className="col-auto" key={student}>
                                <a href={Domain("student","course/"+student)} className="btn btn-secondary" target="_blank">受講コースページ</a>
                            </div>
                        )}</div>:""}
                        </>
                    </div>
                :""}
                <table className="table table-striped table-hover">
                    <tbody>
                    <tr>
                        <th>
                        コース名
                        </th>
                        <td>
                        {Course['CourseName']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        開講期間
                        </th>
                        <td>
                        {ConvertToDisplayDate(Course['term'][0])}から{ConvertToDisplayDate(Course['term'][1])}まで
                        </td>
                    </tr>
                    <tr>
                        <th>
                        講師
                        </th>
                        <td>
                        <div className="list-group list-group-flush">
                            {Course['teachers'].map((teacher,index)=><a href={"/profile/teacher/"+teacher['TeacherId']} className="list-group-item list-group-item-action" target="_blank" key={index}>{teacher.UserName}</a>)}
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                        講義方法
                        </th>
                        <td>
                            {Escape(Course['CourseMethod'])}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        受講レベル
                        </th>
                        <td>
                            {Course['CourseLevel']}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        キーワード
                        </th>
                        <td>
                            {Course['keywords'].map(key=><span key={key}>{key}&nbsp;</span>)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        最大受講者数
                        </th>
                        <td>
                            {Course['MaxStudent']}名
                        </td>
                    </tr>
                    <tr>
                        <th>
                        初期費用
                        </th>
                        <td>
                            {Course['SetupPrice']}円
                        </td>
                    </tr>
                    <tr>
                        <th>
                        月額費用
                        </th>
                        <td>
                            {Course['MonthlyPrice']}円/月{Course['AccountNumber']?' × '+Course['AccountNumber']+'月':''}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        無料期間
                        </th>
                        <td>
                            {CourseFree(Course['free'])}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        講義の目標
                        </th>
                        <td>
                            {Escape(Course['goal'])}
                        </td>
                    </tr>
                    <tr>
                        <th>
                        講義計画
                        </th>
                        <td>
                            <ol>
                                {Course['plans'].map((plan,index)=><li key={index}>{plan}</li>)}
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <th>
                        必要物
                        </th>
                        <td>
                            <ul>
                                {Course['needs'].map((need,index)=><li key={index}>{need}</li>)}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th>
                        受講の注意事項
                        </th>
                        <td>
                            {Escape(Course['attention'])}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            契約書
                        </th>
                        <td>
                            <a href={Course['contract']['link']} target="_blank">{Course['contract']['name']}</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </>:""}
        </div>
    );
}