import Escape from "./Escape";

/**
 *show Alert
 *
 * @export
 * @param {*} props.Item={'type':'danger','style':false,'msgs':[],'description':data['error']['description']}
 * @return {*} 
 */
export default function AlertBox({type,visible,msgs,description}){
    console.log(msgs);
    if(!Array.isArray(msgs)){
        if(typeof msgs==="string"){
            msgs=[msgs];
        }else if(msgs!==undefined){
            let description=Object.assign(msgs);
            msgs=[];
            let keys=Object.keys(description);
            for(let i=0;i<keys.length;++i){
                for(let n=0;n<description[keys[i]].length;++n){
                    msgs.push(description[keys[i]][n]);
                }
            }
        }else{
            msgs=[];
        }
    }
    return(
        <div className="row col-12">
            <div className={"alert alert-"+type} role="alert" style={{display:(visible?'block':'none')}}>
                <ul className="m-0">
                    {msgs.map((msg,index)=>msg?<li key={index}>{Escape(msg)}</li>:"")}
                </ul>
            </div>
        </div>
    );
}