import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { createKey, Domain } from './controller/keys';
import Fetch from './controller/Fetch';

const root=document.getElementById('root');
let ifm=document.createElement('iframe');
ifm.src=Domain('login','get/');
ifm.style.display="none";
root.appendChild(ifm);
let AccessToken={
	LongToken:"",
	ShortToken:"",
	AccessToken:""
};
let rights={
	web:false,
	report:false,
	test:false,
	serma:false
};
let user={
	UserId:"",
	UserName:""
}
ifm.addEventListener('load',function(){
	let nonce=createKey();
	ifm.contentWindow.postMessage({type:'GetSessions',nonce:nonce},Domain('login'));
	window.addEventListener('message',function(event){
		if(event.data.type && event.data.type=='GetSessions' && event.data.nonce===nonce){
			if(event.data.result){
				AccessToken=event.data['access_token'];
				user=event.data['user'];
				rights=event.data['rights'];
			}
			ReactDOM.render(<App />,root);
		}
	},false);
},false);

export function setAccessToken(token){
	AccessToken=token;
}
export function getAccessToken(){
	return AccessToken;
}
export function getUser(){
	return user;
}
export function getRights(){
	return rights;
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
