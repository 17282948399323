import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../controller/Loading";
import AlertBox from "../controller/AlertBox";
import Fetch from "../controller/Fetch";
import Crumb from "../controller/Crumb";
import LineBtn from "./common/LineBtn";
import { Domain, PaymentMethodKey } from "../controller/keys";

export default function Home(){
    const {id}=useParams();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(true);
    const [Welcome,setWelcome]=useState();

    useEffect(()=>{
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            'url':'v1/student/course/'+id+'/welcome',
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setWelcome(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    }
    return (
        <div>
            {Welcome ?<Crumb CurrentText={Welcome['CourseName']} items={[{index:0,href:"/",text:"コース検索"}]} />:""}
            {Alert ?<AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Welcome?
            <div className="row">
                <div className="col-12">
                    <h2>
                    ようこそ！{Welcome['CourseName']}へ
                    </h2>
                    <div className="my-3">
                        <div className="my-4">
                            <h3>
                                受講登録ありがとうございます！
                            </h3>
                            <p>
                            この度は，受講登録頂きありがとうございます！
                            </p>
                            <p>
                            これから皆さんと一緒に多くのことを学べることが楽しみです♪
                            </p>
                            <p>
                            沢山のことを学んでいきましょう！
                            </p>
                        </div>
                        {Welcome['payment']['method']?<Payment payment={Welcome['payment']} />:""}
                        {Welcome['LINE'] && <Line LINE={Welcome['LINE']} />}
                    </div>
                    <div className="mt-2">
                        <a href={Domain('student',"course/"+id)} className="btn btn-primary" target="_blank">
                            コースページ
                        </a>
                    </div>
                </div>
            </div>:""
            }
        </div>
    );
}
function Payment({payment}){
    function Open(){
        if(payment['approve']){
            window.open(payment['approve'],'new-window','width=600px,height=800px');
        }
    }
    const [method,setMethod]=useState();
    useEffect(()=>{
        setMethod(PaymentMethodKey(payment['method']))
        Open();
    },[payment])
    return(
        <>
            {payment['method']!='stripe'?
            <div className="my-4 mt-5">
                <h3>
                    お支払いについて
                </h3>
                <p>
                    お支払方法を{method}で設定しています。
                </p>
                <p>
                    下記のリンクをクリックし，{method}と連携してください。
                </p>
                <p>
                    なお，お支払いが完了するまでは受講が開始できませんので必ずお支払いをしてください。
                </p>
                <button type="button" class="btn btn-danger" onClick={Open}>お支払い</button>
            </div>:''}
        </>
    );
}
function Line({LINE}){
    return(
        <div className="my-4">
            <h3>
                公式LINEについて
            </h3>
            <p>
                このコースは公式LINEと連携されています。
            </p>
            <p>
                公式LINEと連携を行い，公式LINEを追加してください。
            </p>{LINE['login']?
            <div className="my-2">
                <LineBtn Link={LINE['login']} Text={"公式LINEと連携"} />
            </div>:""}{LINE['entry']?
            <div className="my-2">
                <LineBtn Link={LINE['entry']} Text={"公式LINE追加"} target="_blank" />
            </div>:""}
        </div>
    );
}