import { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../controller/Loading";
import AlertBox from "../controller/AlertBox";
import Fetch from "../controller/Fetch";

export default function Home(){
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(false);
    const [Result,setResult]=useState();
    function pressKey(e){
        if(e.key=='Enter'){
            e.preventDefault();
            doSearch();
        }
    }
    function doSearch(){
        setVisible(true);
        const keyword=document.getElementById('keyword');
        let send={
            'url':'v1/syllabus/search/'+encodeURI(keyword.value),
            'method':'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setResult({
                    meta:'"'+keyword.value+'"の検索結果('+data['payloads'].length+'件)',
                    list:data['payloads']
                });
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    }
    return (
        <div>
            {Alert ?<AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />
            <div className="row">
                <div className="col-12">
                <h2>
                    コース検索
                </h2>
                </div>
            </div>
            <div className="row">
                <form className="col-sm-6 col-md-6 form-group my-2">
                    <div className="input-group">
                        <input type="search" id="keyword" className="form-control js-data" placeholder="キーワード" aria-label="キーワード" aria-describedby="student-searchbtn" onKeyDown={pressKey} />
                        <button type="button" id="student_searchbtn" className="btn btn-primary" onClick={doSearch}>
                            検索
                        </button>
                    </div>
                </form>
            </div>{Result ?<>
            <div className="row">
                <div className="col-auto my-3">
                <h3>
                    検索結果
                </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="my-2">
                        {Result['meta']}
                    </div>
                    <div className="list-group list-group-flush">
                        {Result['list'].map(res=><Link to={res.CourseId} className="list-group-item list-group-item-action" key={res.CourseId}>{res.CourseName}</Link>)}
                    </div>
                </div>
            </div></>:""}
        </div>
    );
}