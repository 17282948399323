import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../controller/Loading";
import AlertBox from "../controller/AlertBox";
import Fetch from "../controller/Fetch";
import Crumb from "../controller/Crumb";
import { ConvertToDate, CourseFree, createKey, Domain, number_format, PaymentMethodImg, PaymentMethodKey, PaymentMethodLink } from "../controller/keys";
import { getAccessToken, getUser, setAccessToken } from "..";
import { loadStripe } from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import SendJson from "../controller/SendJson";

let token={};
export default function Home(){
    const {id}=useParams();
    const [Alert,setAlert]=useState();
    const [Visible,setVisible]=useState(false);
    const [Course,setCourse]=useState();
    const [Logged,setLogged]=useState(false);

    const [Accept,setAccept]=useState(false);//ユーザー登録同意
    const [CourseAccept,setCourseAccept]=useState(false);//コース契約同意
    const [Price,setPrice]=useState(0);//支払金額
    
    const [Payment,setPayment]=useState();
    const StripeToken=useRef();

    useEffect(()=>{
        token=getAccessToken();
        if(token['AccessToken']){
            setLogged(true);
            setAccept(true);
        }else{
            setLogged(false);
        }
        Initialize();
    },[])
    function Initialize(){
        setVisible(true);
        let send={
            'url':'v1/syllabus/'+id,
            'method':'GET'
        }
        if(!token['AccessToken']){
            send['url']+='/show';
        }
        Fetch(send).then(data=>{
            if(data['result']){
                setCourse(data['payloads']);
                setPrice(data['payloads']['SetupPrice']+(data['payloads']['free']?0:data['payloads']['MonthlyPrice']));
            }else{
                setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error);
        })
    }
    function changeAccept(e){
        setAccept(e.target.checked);
    }
    function changeCourseAccept(e){
        setCourseAccept(e.target.checked);
    }
    function changePayment(e){
        setPayment(e.target.value);
    }
    function doEntry(){
        setVisible(true);
        if(Logged){
            doAdd();
        }else{
            //アカウント作成
            const {error,body}=SendJson(document.getElementById('UserCreate').getElementsByClassName('js-data'),true);
            if(error.length){
                setAlert({type:'danger',msgs:error});
                setVisible(false)
                return;
            }else{
                let send={
                    url:"v1/user",
                    method:"POST",
                    body:Object.assign(body,{accept:Accept})
                }
                Fetch(send).then(data=>{
                    if(data['result']){
                        window.alert('新しいウィンドウでログインしてください。');
                        window.open(Domain('login'),"new_login","width:600px,height:600px");
                        window.alert('ログインしましたか？');
                        
                        const root=document.getElementById('UserCreate');
                        let ifm=document.createElement('iframe');
                        ifm.src=Domain('login','get/');
                        ifm.style.display="none";
                        root.appendChild(ifm);
                        ifm.addEventListener('load',function(){
                            let nonce=createKey();
                            ifm.contentWindow.postMessage({type:'GetSessions',nonce:nonce},Domain('login'));
                            window.addEventListener('message',function(event){
                                if(event.data.type && event.data.type=='GetSessions' && event.data.nonce===nonce){
                                    if(event.data.result){
                                        setAccessToken(event.data['access_token']);
                                        doAdd();
                                    }
                                }
                            },false);
                        },false);

                    }else{
                        setAlert({type:'danger',msgs:data['error']['description']});
                        setVisible(false);
                    }
                }).catch(e=>{
                    console.log(e);
                    setVisible(false);
                })
            }
        }
    }
    function doAdd(){
        let {error,body}=SendJson(document.getElementById('CourseAdd').getElementsByClassName('js-data'),true);
        if(error.length){
            setAlert({'type':'danger','style':true,'msgs':error});
			setVisible(false);
            window.scroll({top:0});

            return;

        }else{
            let send={
                'url':'/v1/student/course/'+id,
                'method':'PUT',
                'body':{
                    "contracts": {
                        "FileId": Course['contract']['FileId'],
                        "name": body['AcceptName'],
                        "date": body['AcceptDate']
                    },
                    "payments": {
                        "PaymentMethod": Payment,
                        "StripeToken":StripeToken.current.value
                    }
                }
            }

            Fetch(send).then(data=>{
                if(data['result']){
                    let StudentId=data['payloads']['StudentId'];

                    window.location.href=Domain('course',StudentId+"/welcome")
                    setAlert({'type':'success','style':true,'msgs':['コースの受講登録をしました。']});
                }else{
                    setAlert({'type':'danger','style':true,'msgs':data['error']['description']});
                }
                setVisible(false);
                window.scroll({top:0});
            }).catch((error)=>{
                setVisible(false);

                console.log(error);
            });
        }
    }
    return (
        <div>
            {Course ?<Crumb CurrentText={Course['CourseName']} items={[{index:0,href:"/",text:"コース検索"},{index:1,href:"/"+id,text:Course['CourseName']}]} />:""}
            {Alert ?<AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Course?
            <div className="row">
                <div className="col-sm-6 my-2 mb-5">
                    <a href={Course['contract']['download']} target="_blank">契約書のダウンロード</a>
                    <iframe src={Course['contract']['link']} className="w-100 h-100 my-2 iframe-file" />
                </div>
                <div className="col-sm-6 my-2 mb-5">
                    <form>{Logged?"":
                        <div id="UserCreate">
                            <div>
                                <span>アカウントをお持ちですか？</span>
                                <a href={Domain("login","?redirect="+window.location.href)}>ログイン</a>
                            </div>
                            <div className="input-group my-2">
                                <span className="input-group-text">メールアドレス</span>
                                <input type="email" name="email" className="form-control js-data" placeholder="メールアドレス" required />
                            </div>
                            <div className="input-group my-2">
                                <span className="input-group-text">パスワード</span>
                                <input type="password" name="password" className="form-control js-data" placeholder="新しいパスワード" autoComplete="new-password" required />
                            </div>
                            <div className="input-group my-2">
                                <span className="input-group-text">確認用パスワード</span>
                                <input type="password" name="password_confirmation" className="form-control js-data" placeholder="確認用パスワード" autoComplete="new-password" required />
                            </div>
                            <div className="my-2">
                                <input type="checkbox" name="accept" id="acceptance" className="form-check-input" value="true" onChange={changeAccept} required />&nbsp;
                                <label htmlFor="acceptance" className="form-label">
                                    CanTeachアカウント作成に関し，
                                </label>
                                <a href="https://canteach.jp/canteach-term/" target="_blank">CanTeach利用規約</a>と<a href="https://canteach.jp/privacy-policy/" target="_blank">プライバシーポリシー</a>
                                <label htmlFor="acceptance" className="form-label">
                                    を確認の上，同意します。
                                </label>
                            </div>
                        </div>
                    }
                        <div id="CourseAdd" style={{display:(Accept?'block':"none")}}>
                            <div className="mb-2">
                                <div className="input-group my-2">
                                    <span className="input-group-text">同意日</span>
                                    <input type="date" id="acceptance_date" name="AcceptDate" className="form-control js-data" defaultValue={ConvertToDate(new Date(),true)} required readOnly />
                                </div>
                                <div className="input-group my-2">
                                    <span className="input-group-text">名前</span>
                                    <input type="text" id="acceptance_name" name="AcceptName" className="form-control js-data" defaultValue={Logged?getUser()['UserName']:''} placeholder="名前" required />
                                </div>
                                <div className="my-2">
                                    <input type="checkbox" id="CourseAcceptance" className="form-check-input" defaultValue={true} onChange={changeCourseAccept} required />
                                    <label htmlFor="CourseAcceptance" className="input-check-label">
                                        &nbsp;{Course['CourseName']}の受講契約に関し，契約書を確認の上，同意する
                                    </label>
                                </div>
                            </div>
                            <div className="my-3" style={{display:CourseAccept?'block':'none'}}>
                                <h3>
                                    お支払金額
                                </h3>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <th>
                                            入会金
                                            </th>
                                            <td>
                                            {number_format(Course['SetupPrice'])}円
                                            </td>
                                        </tr>{Course['free']?
                                        <tr>
                                            <th>
                                            無料期間
                                            </th>
                                            <td>
                                            {CourseFree(Course['free'])}
                                            </td>
                                        </tr>:""}
                                        <tr>
                                            <th>
                                            受講料
                                            </th>
                                            <td>
                                            {number_format(Course['MonthlyPrice'])}円/月
                                            </td>
                                        </tr>{Course['MonthlyPrice']?
                                        <tr>
                                            <th>
                                            契約月数
                                            </th>
                                            <td>
                                                {Course['AccountNumber']?number_format(Course['AccountNumber'])+'月':"解約まで"}
                                            </td>
                                        </tr>:""}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>
                                            合計金額
                                            </th>
                                            <td>
                                            入会金：{number_format(Course['SetupPrice'])}円{Course['MonthlyPrice']?'+受講料：'+number_format(Course['MonthlyPrice'])+'円/月'+(Course['AccountNumber']?'×契約月数：'+number_format(Course['AccountNumber'])+'月':''):""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                            初回支払金額
                                            </th>
                                            <td>
                                            {number_format(Price)}円
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>{(Course['SetupPrice']>0 || Course['MonthlyPrice']>0)?
                            <div className="my-3" style={{display:((CourseAccept)?'block':'none')}}>
                                <h3>
                                    お支払
                                </h3>
                                <label className="col-sm-2 col-form-label">
                                    お支払い方法
                                </label>
                                <div className="col-sm-10">
                                    <select name="PaymentMethod" className={"form-select js-data"} onChange={changePayment} required>
                                        <option label="選択してください" style={{display:"none"}}></option>{Course['payment'].map(method=>
                                        <option value={method['method']} key={method['method']}>{PaymentMethodKey(method['method'])}</option>)}
                                    </select>
                                    <div className="row mt-2" style={{maxWidth:'700px'}}>{Course['payment'].map(method=>
                                        <div className="col-sm-3" key={method['method']}>
                                            <a href={PaymentMethodLink(method['method'])} target="_blank">
                                                <img src={PaymentMethodImg(method['method'])} alt={PaymentMethodKey(method['method'])} className="w-100 h-100" />
                                            </a>
                                        </div>)}
                                    </div>{Course['payment'].map(method=>method['method']==='stripe'?Payment=='stripe'?
                                    <div key={method['method']}>
                                        <input type="hidden" name="StripeToken" className="js-data" ref={StripeToken} />
                                        <Stripe PublicKey={method['apiKey']} Token={StripeToken} />
                                    </div>:"":"")}
                                </div>
                            </div>:""}
                            <div className="mb-3">{(Accept && CourseAccept && (Payment || (Course['SetupPrice']==0 && Course['MonthlyPrice']==0)))?
                                <button type="button" className="btn btn-primary" onClick={doEntry}>
                                    受講登録
                                </button>:""}
                            </div>
                        </div>
                    </form>
                </div>
            </div>:""}
        </div>
    );
}
let stripePromise;
function Stripe({PublicKey,Token}){
    stripePromise=loadStripe(PublicKey);
    return(
        <>{PublicKey?
            <Elements stripe={stripePromise}>
                <StripeElement Token={Token} />
            </Elements>:
        ''}
        </>
    );
}
function StripeElement({Token}){
    const stripe=useStripe();
    const elements=useElements();

    const [Alert,setAlert]=useState(false);
    const [Card,setCard]=useState({cardNumber:false,cardExpiry:false,cardCvc:false});//cardNumber,expiry,cvc
    const [Errors,setErrors]=useState({cardNumber:'',cardExpiry:'',cardCvc:''});
	const [Visible,setVisible]=useState(false);

    function displayCardError(e){
        changeError(e);
    }
    function displayExpiryError(e){
        changeError(e);
    }
    function displayCvcError(e){
        changeError(e);
    }
    function checkCard(card){
        setCard(card);
        if(!card['cardNumber']){
            elements.getElement('cardNumber').focus();
        }else if(!card['cardExpiry']){
            elements.getElement('cardExpiry').focus();
        }else if(!card['cardCvc']){
            elements.getElement('cardCvc').focus();
        }else if(card['cardNumber'] && card['cardExpiry'] && card['cardCvc']){
            doEntry();
        }
    }
    function changeError(e){
        let error={};
        let card={};
        error[e.elementType]=e.error?e.error.message:false;
        card[e.elementType]=!e.error;
        if(e.complete){
            checkCard(Object.assign(Card,card));
            setErrors(Object.assign(Errors,error));
        }else{
            checkCard(Object.assign(Card,card));
            error=Object.assign(Errors,error);
            setErrors(error);
        }
        let check=(error['cardNumber'] || error['cardExpiry'] || error['CardCvc']);
        setAlert({'type':'danger','style':true,'msgs':check?[Errors['cardNumber'],Errors['cardExpiry'],Errors['cardCvc']]:[""]});
        Token.current.value="";
    }
    function doEntry(){
        setVisible(true);
        if(!stripe || !elements){
            setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
            Token.current.value="";
            setVisible(false);
        }else{
            /*stripe.createSource(elements.getElement('cardNumber'),{
                type:'card',
                currency:'jpy'
            })*/
            stripe.createPaymentMethod({
                type:'card',
                card:elements.getElement('cardNumber')
            }).then(result=>{
                if(result.error){
                    setAlert({'type':'danger','style':true,'msgs':[result.error.message]});
                }else{
                    setAlert({'type':'success','style':true,'msgs':["カードを利用できます。"]});
                    Token.current.value=result.paymentMethod.id;
                }
                setVisible(false);
            }).catch(error=>{
                setAlert({'type':'danger','style':true,'msgs':['エラーが発生しました。']});
                console.log(error)
                Token.current.value="";
                setVisible(false);
            });
        }
    }
    return(
        <form id="StripeForm" className="my-2">
            <Loading visible={Visible} msgs={[]} />
            <div className="input-group mb-2">
                <span className="input-group-text">カード番号</span>
                <CardNumberElement id="card" className="form-control" onChange={displayCardError} options={{showIcon:true,iconStyle:'default'}} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">有効期限</span>
                <CardExpiryElement className="form-control" onChange={displayExpiryError} />
            </div>
            <div className="input-group my-2">
                <span className="input-group-text">セキュリティーコード</span>
                <CardCvcElement className="form-control" onChange={displayCvcError} />
            </div>{/*
            <div className="my-2">
                <label>
                    <input type="checkbox" name="StripeRemember" className="form-check-input js-data" value="true" data-type="boolean" />
                    カード情報を紐づける
                </label>
            </div>*/}
            <div className="mt-2">
                カード情報は<a href="https://stripe.com" target="_blank">Stripe</a>に送信され，CanTeachのサーバーには送信されません。<br/>
                支払いのため，カード情報はStripeに保存されます。
            </div>
            {Alert ? <AlertBox type={Alert['type']} msgs={Alert['msgs']} visible={true} />:""}
        </form>
    );
}